import Link from 'next/link';

import { useUIContext } from '@/components/context/uiContext/ManagedUiContext';
import { DEVICE_SURFACE, PRODUCT_TYPE } from '@/config/common';
import {
  formatDiscountObjectToString,
  parseFormatPriceValueFromAPI
} from '@/lib/numberStringUtils';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const AvailableDiscount = ({ availableProductDiscount, productPrice }) => (
  <div className='text-nero leading-13px text-sm ps-3 flex items-center'>
    <div className='line-through text-dim-gray font-Montserrat font-medium'>
      AED {parseFormatPriceValueFromAPI(productPrice.price)}
    </div>
    <span className='text-green ps-3 font-Montserrat font-medium'>
      {formatDiscountObjectToString(availableProductDiscount)}
    </span>
  </div>
);

const CartProductPriceDisplay = ({
  availableProductDiscount,
  productPrice
}) => {
  const { surface } = useUIContext();
  const isMobile = surface === DEVICE_SURFACE.MOBILE;
  return (
    <div className={`flex items-center ${isMobile && 'mt-2.5'}`}>
      <div className='font-medium font-Montserrat text-nero text-base'>
        AED{' '}
        {productPrice?.priceToApply
          ? parseFormatPriceValueFromAPI(productPrice.priceToApply)
          : ''}
      </div>
      {availableProductDiscount?.value && (
        <AvailableDiscount
          {...{
            availableProductDiscount,
            productPrice
          }}
        />
      )}
    </div>
  );
};

const QuoteProductPriceDisplay = ({ productPrice, productSlug }) => (
  <Link
    href={getPageURL({
      pageName: ROUTE_CONFIG.PDP.label,
      pathParams: {
        slug: productSlug
      }
    })}
  >
    <a className='font-medium font-Montserrat text-nero leading-19px text-base md:leading-5 mt-2 block'>
      Price Starting from AED
      {parseFormatPriceValueFromAPI(productPrice.minPrice)}
    </a>
  </Link>
);

const ProductTypeToPriceDisplayMap = {
  [PRODUCT_TYPE.CART]: CartProductPriceDisplay,
  [PRODUCT_TYPE.QUOTE]: QuoteProductPriceDisplay
};

const ProductPrice = ({ product }) => {
  const {
    availableDiscount: availableProductDiscount,
    price: productPrice,
    slug: productSlug,
    type: productType
  } = product;
  const ProductTypeBasedPriceDisplay =
    ProductTypeToPriceDisplayMap[productType];

  return (
    <ProductTypeBasedPriceDisplay
      {...{ productPrice, availableProductDiscount, productSlug }}
    />
  );
};

export default ProductPrice;
