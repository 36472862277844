import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useScrollData } from 'scroll-data-hook';

import { staticMediaStoreBaseURL } from '@/config/common';
import phoneConfig from '@/config/phone';
import { getFormattedNumber, PNF } from '@/helpers/formatPhoneNumber';
import { captureGTMEventWhatsappClick } from '@/lib/gtm';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';
import { getInitiateWhatsappChatLink } from '@/services/user.service';

const { phoneNumbers } = phoneConfig;

const uaeLandlineNumber = getFormattedNumber(
  phoneNumbers.haflaLandlineMaqsam,
  'UAE',
  PNF.E164
);

const homePageURL = getPageURL({
  pageName: ROUTE_CONFIG.HOME_PAGE.label
});

const MobileHomeHeader = ({ isHomePage = false, showSearchModal }) => {
  const [onScrolling, setOnScrolling] = useState(false);
  const { position } = useScrollData({});
  const isHomePageAndNotScrolling = !onScrolling && isHomePage;

  useEffect(() => {
    if (position.y > 477 && !onScrolling) {
      setOnScrolling(true);
    } else if (position.y < 477 && onScrolling) {
      setOnScrolling(false);
    }
  }, [position.y]);

  const openWhatsappInNewTab = async () => {
    captureGTMEventWhatsappClick();
    const initiateWhatsappChatLink = await getInitiateWhatsappChatLink();
    window.open(
      initiateWhatsappChatLink,
      '_blank',
      'noopener, noreferrer,nofollow'
    );
  };

  return (
    <section
      className={`${isHomePageAndNotScrolling ? 'pb-0' : 'pb-12.5'}`}
      id='header-section'
    >
      <div
        className={`h-12.5 items-center px-5 py-3.5 flex justify-between bg-white ${
          isHomePageAndNotScrolling ? 'bg-opacity-0 pb-2' : 'shadow'
        } ${
          !showSearchModal && onScrolling
            ? 'fixed sticky-header z-120 w-full'
            : 'absolute w-full top-0'
        }`}
      >
        <Link href={homePageURL}>
          <a>
            <Image
              alt='Hafla'
              className='block cursor-pointer max-w-17.5 h-6 w-full'
              height={24}
              src={`${staticMediaStoreBaseURL}/logos/${
                isHomePageAndNotScrolling ? 'hafla-white' : 'hafla_new'
              }.svg`}
              width={70}
            />
          </a>
        </Link>

        <div className='flex gap-4 z-20'>
          <div className='relative'>
            <a
              href={`tel:${uaeLandlineNumber}`}
              id='call-icon'
            >
              <Image
                alt='Call'
                className='cursor-pointer w-full'
                height={24}
                layout='fixed'
                src={`${staticMediaStoreBaseURL}/icons/call-round-mobile.svg`}
                width={24}
              />
            </a>
          </div>

          <div
            className='relative z-20'
            onClick={openWhatsappInNewTab}
          >
            <Image
              alt='Whatsapp'
              className='cursor-pointer w-full'
              height={isHomePageAndNotScrolling ? 24 : 26}
              layout='fixed'
              src={`${staticMediaStoreBaseURL}/icons/whatsapp-green.svg`}
              width={isHomePageAndNotScrolling ? 24 : 26}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileHomeHeader;
