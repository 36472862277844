import Image from 'next/image';
import Link from 'next/link';

import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const homePageURL = getPageURL({
  pageName: ROUTE_CONFIG.HOME_PAGE.label
});

const Logo = ({ baseUrl, filePath }) => (
  <Link
    className='cursor-pointer'
    href={homePageURL}
  >
    <a>
      <Image
        alt='Hafla'
        className='inline-block hafla-header-img md:26px cursor-pointer'
        height={50}
        layout='fixed'
        priority
        src={`${baseUrl}${filePath}`}
        width={50}
      />
    </a>
  </Link>
);

export default Logo;
